import { ASSET_PATH, BUCKET_URL, IS_PROD } from '@/common/globals';
import i18n from '@/plugins/i18n';
import createVuetify from '@/plugins/vuetify';
import CKEditor from '@ckeditor/ckeditor5-vue';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueGtag from 'vue-gtag';

import initSite from '@/common/siteInit';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import ApiService from './common/api.service';
import GlobalComponents from './common/globalComponents';
import GlobalFilters from './common/globalFilters';
import GlobalFunctions from './common/globalFunctions';
import GlobalPlugins from './common/globalPlugins';

Vue.prototype.$bucketUrl = BUCKET_URL;
Vue.prototype.$assetPath = ASSET_PATH;
Vue.config.productionTip = false;

Vue.use(VueCookies);

Vue.use(GlobalPlugins);
Vue.use(GlobalComponents);
Vue.use(GlobalFunctions);
Vue.use(GlobalFilters);
Vue.use(CKEditor);

if (IS_PROD) {
	Vue.use(
		VueGtag,
		{
			bootstrap: false,
		},
		router,
	);
}

ApiService.init(store);

store.dispatch('initCompany').then((response) => {
	initSite({ settings: response, router });

	const vuetify = createVuetify(response);

	new Vue({
		router,
		store,
		vuetify,
		i18n,
		render: (h) => h(App),
		beforeCreate() {
			// API service init

			if (store.getters.isAuthenticated) {
				store.dispatch('getPermission', {
					groupName: 'All',
					viewAs: store.getters.isViewUser,
				});
			}
		},
	}).$mount('#app');
});
