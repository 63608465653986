export const convertToCamelcase = (object) => {
	let build;
	let destKey;
	let value;

	if (object instanceof Array) {
		build = [];
		Object.keys(object).forEach((key) => {
			value = object[key];
			if (typeof value === 'object') {
				value = convertToCamelcase(value);
			}
			build.push(value);
		});
	} else {
		build = {};
		let hasAtleastOneOwnProperty = false;
		Object.keys(object).forEach((key) => {
			hasAtleastOneOwnProperty = true;
			destKey = (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();
			value = object[key];
			if (value !== null && typeof value === 'object') {
				value = convertToCamelcase(value);
			}
			build[destKey] = value;
		});
		if (!hasAtleastOneOwnProperty) {
			build = object;
		}
	}
	return build;
};

export const mapTwoWay = (namespace, getter, action) => ({
	get() {
		return this.$store.getters[namespace ? `${namespace}/${getter}` : getter];
	},
	set(value) {
		this.$store.dispatch(namespace ? `${namespace}/${action}` : action, value);
	},
});

export const toTitleCase = (phrase) =>
	phrase
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

export const capitalize = (input) => {
	const letters = { i: 'İ', ş: 'Ş', ğ: 'Ğ', ü: 'Ü', ö: 'Ö', ç: 'Ç', ı: 'I' };
	const value = input.replace(/(([iışğüçö]))/g, (letter) => letters[letter]);
	return value.toUpperCase();
};

export const traverse = (obj, keys) => keys.split('.').reduce((cur, key) => cur[key], obj);

export const saveByteArray = (fileName, fileType, byte) => {
	const base64ToArrayBuffer = (base64) => {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	};

	const sampleArr = base64ToArrayBuffer(byte);

	const blob = new Blob([sampleArr], { type: fileType });
	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = fileName;
	link.click();
};

export const camelCase = (str) =>
	str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
		.replace(/\s+/g, '');

export const jsonValid = (json) =>
	json
		.replace(/'/g, '"')
		// Replace ":" with "@colon@" if it's between double-quotes
		.replace(/:\s*"([^"]*)"/g, (match, p1) => `: "${p1.replace(/:/g, '@colon@')}"`)
		// Replace ":" with "@colon@" if it's between single-quotes
		.replace(/:\s*'([^']*)'/g, (match, p1) => `: "${p1.replace(/:/g, '@colon@')}"`)
		// Add double-quotes around any tokens before the remaining ":"
		.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?\s*:/g, '"$2": ')
		// Turn "@colon@" back into ":"
		.replace(/@colon@/g, ':');

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const hexToRgb = (hex) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
};

export const fieldSorter = (fields) => (a, b) =>
	fields
		.map((o) => {
			let object = o;
			let dir = 1;
			if (o[0] === '-') {
				dir = -1;
				object = object.substring(1);
			}
			if (a[object] > b[object]) return dir;
			if (a[object] < b[object]) return -dir;
			return 0;
		})
		.reduce((p, n) => p || n, 0);

export const hexToRgb2 = (hex) =>
	((value) =>
		value.length === 3
			? value.split('').map((c) => parseInt(c.repeat(2), 16))
			: value.match(/.{1,2}/g).map((v) => parseInt(v, 16)))(hex.replace('#', ''));

// Luma - https://stackoverflow.com/a/12043228/1762224
export const isHexTooDark = (hexColor) =>
	(([r, g, b]) => 0.2126 * r + 0.7152 * g + 0.0722 * b < 40)(hexToRgb(hexColor));

// Brightness - https://stackoverflow.com/a/51567564/1762224
export const isHexTooLight = (hexColor) =>
	(([r, g, b]) => (r * 299 + g * 587 + b * 114) / 1000 > 155)(hexToRgb(hexColor));

export const getContrast50 = (hexcolor) => {
	const r = parseInt(hexcolor.substr(0, 2), 16);
	const g = parseInt(hexcolor.substr(2, 2), 16);
	const b = parseInt(hexcolor.substr(4, 2), 16);
	const yiq = (r * 299 + g * 587 + b * 114) / 1000;
	return yiq >= 128 ? 'black' : 'white';
};

export const base64ToArrayBuffer = (base64) => {
	const binaryString = window.atob(base64.split(',')[1]);
	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i + 1) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes;
};

export const base64ToArrayBufferChunk = (base64, chunkSize) => {
	const binaryString = window.atob(base64.split(',')[1]);
	const len = binaryString.length;
	const chunks = [];

	for (let i = 0; i < len; i += chunkSize) {
		chunks.push(binaryString.slice(i, i + chunkSize));
	}

	const bytes = new Uint8Array(len);
	chunks.forEach((chunk, index) => {
		for (let j = 0; j < chunk.length; j + 1) {
			bytes[index * chunkSize + j] = chunk.charCodeAt(j);
		}
	});

	return bytes.buffer;
};

export const getTimezone = () => {
	const now = new Date();
	const timezoneOffsetMinutes = now.getTimezoneOffset();
	const timezoneOffsetHours = -(timezoneOffsetMinutes / 60);

	return timezoneOffsetHours;
};

export default {
	camelCase,
	convertToCamelcase,
	mapTwoWay,
	toTitleCase,
	capitalize,
	traverse,
	saveByteArray,
	jsonValid,
	sleep,
	hexToRgb,
	fieldSorter,
	hexToRgb2,
	isHexTooDark,
	isHexTooLight,
	getContrast50,
	base64ToArrayBuffer,
	// getDataRowAttr,
	getTimezone,
};
